@import "../layout/_vars";

.brandLogo {
    width: 150px;
    height: 150px;
    display: block;
    background-image: url('/assets/logo-yellow.svg');
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto 2rem;
    

    @include media-breakpoint-down(sm) {
        max-width: 100px;
        margin: 0 auto 1rem;
    }
}

.contentWrapper {
    background-color: #f7f7f7;
    color: #fff;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
}

.content {
    max-width: 600px;
    padding: 120px 50px;
    background-color: $tote-secondary;
    color: $tote-secondary-text;

    .contentHeader {
        margin-bottom: 3rem;
        text-align: center;

        h1,
        p {
            font-weight: 300;
        }
        h1 {
            text-transform: uppercase;
        }
        p {
            margin-bottom: 1.5rem;
            font-size: 1.2rem;
            letter-spacing: 0.2px;
        }

        @include media-breakpoint-down(sm) {
            h1 {
                font-size: 2rem;
            }
        }
    }

    :global {
        .form-label {
            margin-bottom: 0.25rem;
        }
        .form-control {
            background: transparent;
            border: 0;
            border-radius: 0;
            border-bottom: 1px solid $tote-secondary-text;
            color: $tote-secondary-text;
            transition: border-bottom-width 0.1s;
            padding-left: 5px;
            padding-right: 5px;

            &:hover {
                border-bottom-color: lighten($tote-secondary-text, 10%);
            }
            &:focus {
                border-bottom-width: 3px;
                box-shadow: none;
                background-color: transparent;
                color: $tote-secondary-text;
                border-color: #fff;
            }
            &::placeholder {
                color: rgba($tote-secondary-text, 0.6);
            }
            &[readonly] {
                background-color: transparent;
            }
        }

        .invalid-feedback {
            background-color: rgba(#fff, 0.85);
            border-radius: 5px;
            padding: 5px;
        }
        .was-validated .custom-control-input:invalid ~ .custom-control-label,
        .custom-control-input.is-invalid ~ .custom-control-label {
            color: inherit;
        }

        .input-group-text {
            background-color: transparent;
            border: 0;
            border-bottom: 1px solid #fff;
            border-radius: 0;
            color: $tote-secondary-text;
        }

        .btn {
            text-transform: uppercase;
        }
        .btn-link {
            color: rgba($tote-secondary-text, 0.85);
            &:hover {
                color: rgba($tote-secondary-text, 0.85);
            }

            .register-link {
                color: inherit;
            }
        }

        .custom-control-label a {
            color: inherit;
            text-decoration: underline;
        }

        .radioToggleGroup {
            label {
                padding: 16px;
                font-size: 1.1rem;
                margin: 4px 0;
                background-color: transparent;
                color: $tote-secondary-text;
                border: 2px solid darken($tote-secondary, 15%);

                &:hover {
                    border-color: $tote-secondary-text;
                }
            }
            input[type="radio"],
            input[type="checkbox"] {
                &:checked + label {
                    border-color: $tote-secondary-text;
                    background-color: $tote-secondary-text;
                    color: #fff;
                }
            }
        }
    }
}


.pack {
    display: flex;
    flex-direction: column;
}